<template>
  <div class="global">
    <div class="flex flex-row global-contain">
      <div class="drawer flex flex-column">
        <div
          v-for="(menu, index) in menus"
          :key="index"
          @click="changeMenu(menu.id)"
          class="flex flex-column align-items-center px-2 py-3 menu-item-drawer"
          :style="{
            borderRight: currentMenu == menu.id ? 'none' : '1px solid #dee2e6',
            'border-top-right-radius': currentMenu + 1 == menu.id ? '10px' : '0px',
            'border-bottom-right-radius':
              currentMenu != 1 && menu.id == currentMenu - 1 ? '10px' : '0px',
            borderBottom:
              currentMenu != 1 && menu.id == currentMenu - 1
                ? '1px solid #dee2e6'
                : 'none',
            borderTop: currentMenu + 1 == menu.id ? '1px solid #dee2e6' : 'none',
          }"
        >
          <Icon :icon="menu.icon" width="28" height="28" />
          <span class="font-medium">{{ menu.text }}</span>
        </div>
        <div class="box"></div>
      </div>
      <div
        :style="{
          width: drawer ? '300px' : '10px',
          transition: 'width 500ms',
        }"
      >
        <div ref="drawerBox" class="menu-details">
          <transition
            v-if="drawer"
            :leave-active-class="
              currentMenu > beforeMenu
                ? 'animate__animated animate__fadeOutUp max__speed'
                : 'animate__animated animate__fadeOutDown max__speed'
            "
            enter-active-class="animate__animated animate__fadeIn transition-duration-100"
            mode="out-in"
          >
            <div class="p-3" v-if="currentMenu === 1">
              <file-pond
                name="test"
                ref="pond"
                label-idle="
              <i class='pi pi-cloud-upload text-indigo-500' style='font-size: 2rem'></i><br>
               Drag and drop files here<br>
              PNG, JPG, JPEG or PDF</span></br>
              <div class='filepond--label-action flex align-items-center justify-content-center text-base font-semibold mt-3'>Choose a file</div>"
                accepted-file-types="image/jpeg, image/png, application/pdf"
                :files="myFiles"
                @addfile="uploadFile"
              />
            </div>
            <div v-else-if="currentMenu === 2">
              <AutoComplete
                class="m-3 mb-8"
                style="width: 90%"
                v-model="selectedPlant"
                :suggestions="filteredPlants"
                @complete="searchPlants($event)"
                @item-select="addPlant()"
                field="LatinName"
              />
              <div
                v-for="plant in selectedPlants"
                :key="plant.id"
                class="plant-box px-2 flex flex-row align-items-center justify-content-between w-full"
                :style="{
                  backgroundColor: plant.active ? 'var(--indigo-100)' : '',
                }"
                @click="selectPlant(plant)"
              >
                <div class="flex flex-row py-2 align-items-center">
                  <Avatar
                    :label="plant.id"
                    class="mr-2"
                    :style="{ backgroundColor: plant.color, color: '#ffffff' }"
                    shape="circle"
                  />
                  <span>{{ plant.name }}</span>
                </div>
                <span class="mr-3 text-sm">{{ plant.quantity }}</span>
              </div>
            </div>
          </transition>
        </div>
        <div
          v-if="drawerBox"
          class="toggle-button flex justify-content-end align-items-center"
          :style="{ right: drawer === true ? '-268px' : '24px' }"
          @click="toggle"
        >
          <i v-if="drawer" class="pi pi-angle-left"></i>
          <i v-else class="pi pi-angle-right"></i>
        </div>
      </div>

      <div class="contain flex flex-column">
        <div
          class="contain-toolbar flex flex-row justify-content-between align-items-center shadow-1 mb-2 py-2 px-5"
        >
          <div>
            <Button
              icon="pi pi-undo"
              @click="handleUndo"
              class="p-button-rounded p-button-secondary p-button-text"
            />
            <Button
              icon="pi pi-refresh"
              @click="handleRedo"
              class="p-button-rounded p-button-secondary p-button-text"
            />
          </div>
          <div class="flex align-items-center">
            <Button label="Save" class="p-button-sm mr-1" @click="save()" />
            <Icon icon="fluent:delete-24-regular" width="21" height="21" class="m-2" />
          </div>
        </div>
        <div id="stageContainer" class="shadow-2" @click="stageClick($event)"></div>
        <div
          class="contain-toolbar flex flex-row shadow-1 justify-content-between align-items-center mt-2 py-2 px-5"
        >
          <div></div>
          <div class="flex flex-row align-items-center">
            {{ zoom }}%
            <Slider
              v-model="zoom"
              class="ml-2"
              @slideend="zoomStage($event)"
              style="width: 200px"
            />
            <Icon icon="ic:baseline-zoom-out-map" width="24" height="24" class="ml-3" />
            <Divider layout="vertical" />
            <Icon
              icon="gridicons:help"
              width="24"
              height="24"
              class="animate__animated animate__pulse animate__infinite"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Konva from "konva";
import { onMounted, ref } from "vue-demi";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "animate.css";
import { useToast } from "primevue/usetoast";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { usePlantService } from "../../compositions/services/usePlantService";

const FilePond = vueFilePond(FilePondPluginFileValidateType);
export default {
  components: {
    FilePond,
  },
  setup() {
    let stage = null;
    let background = null;
    let layer = null;
    let tr = null;
    let cancelButton = null;
    let history = [];
    let historyStep = 0;
    const toast = useToast();
    const drawerBox = ref(null);
    const selectedPlant = ref("");
    const selectedPlants = ref([]);
    const filteredPlants = ref();
    const drawer = ref(true);
    const actived = ref(false);
    const beforeMenu = ref(0);
    const currentMenu = ref(1);
    const zoom = ref(0);
    const plants = ref([]);
    const activePlant = ref();
    const { getValueSearch } = usePlantService();
    const menus = ref([
      {
        id: 1,
        icon: "ant-design:cloud-upload-outlined",
        text: "Importer",
        active: false,
      },
      {
        id: 2,
        icon: "ri:plant-line",
        text: "Plants",
        active: false,
      },
      {
        id: 3,
        icon: "eva:text-outline",
        text: "Texte",
        active: false,
      },
      {
        id: 4,
        icon: "",
        text: "",
        active: false,
      },
    ]);
    const searchPlants = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredPlants.value = [...plants.value];
        } else {
          getValueSearch(event.query.toLowerCase())
            .then(({ data }) => {
              plants.value = data;
              filteredPlants.value = plants.value.filter((plant) => {
                return plant.LatinName.toLowerCase().startsWith(
                  event.query.toLowerCase()
                );
              });
            })
            .catch((err) =>
              toast.add({
                severity: "error",
                summary: "Error",
                detail: err.message,
                life: "3000",
              })
            );
        }
      }, 250);
    };

    selectedPlants.value = [
      {
        id: "1",
        name: "Palmier abricot",
        color: "#6b71fb",
        quantity: 0,
        info: {
          id: 2745,
          date_created: "2022-03-02T09:28:08.554Z",
          date_updated: null,
          Name: "Palmier abricot",
          LatinName: "Butia Capitata",
          Preview: "2d239d7b-a68b-4213-bc48-80d875b57ed3",
          FlowerSize: null,
          BlossomPeriod: null,
          PlantingPeriod: null,
          FlowerForm: null,
          Hash: null,
          SunExposure: ["full-sun", "partial-shade"],
          Height: 5,
          Spread: 4,
          Density: null,
          Type: "tree-and-shrub",
          SoilType: ["clay", "clay-limestone", "clay-silty", "rocky", "limestone"],
          SoilPh: null,
          SoilMoisture: null,
          ZoneUSDA: ["8A", "9A", "10A"],
          ByPartner: [],
          Moods: [4],
        },
        active: false,
      },
      {
        id: "2",
        name: "Palmier chanvre",
        color: "#9bd77a",
        quantity: 0,
        info: {
          id: 2816,
          date_created: "2022-03-02T09:32:02.978Z",
          date_updated: null,
          Name: "Palmier chanvre",
          LatinName: "Chamaerops (trachycarpus) fortunei",
          Preview: "9a0ef2fe-e6a0-41dc-aa6d-f653b9717812",
          FlowerSize: null,
          BlossomPeriod: null,
          PlantingPeriod: null,
          FlowerForm: null,
          Hash: null,
          SunExposure: ["full-sun", "partial-shade"],
          Height: null,
          Spread: null,
          Density: null,
          Type: "tree-and-shrub",
          SoilType: ["clay", "clay-limestone", "clay-silty", "rocky", "limestone"],
          SoilPh: null,
          SoilMoisture: null,
          ZoneUSDA: ["6A", "7B", "8A", "9A"],
          ByPartner: [],
          Moods: [5],
        },
        active: false,
      },
      {
        id: "3",
        name: "Bambou non traçant.",
        color: "#8c7520",
        quantity: 0,
        info: {
          id: 2130,
          date_created: "2022-03-02T08:53:31.421Z",
          date_updated: "2022-03-02T12:53:30.076Z",
          Name: "Bambou non traçant.",
          LatinName: "Fargesia sp Jiuzhaigou Deep Purple",
          Preview: "c53f0723-91d2-4e69-acfe-8aceee1fdfcf",
          FlowerSize: null,
          BlossomPeriod: null,
          PlantingPeriod: ["3", "4", "11", "2", "9", "10"],
          FlowerForm: null,
          Hash: "promesse:fargesia-sp-jiuzhaigou-deep-purple-bambou-non-tracant",
          SunExposure: ["partial-shade", "full-shade"],
          Height: 2.5,
          Spread: 2,
          Density: 1,
          Type: "tree-and-shrub",
          SoilType: ["clay", "clay-silty"],
          SoilPh: ["neutral", "neutral", "acid", "alkaline"],
          SoilMoisture: ["wet"],
          ZoneUSDA: ["6A", "6B", "7A", "7B", "8A", "8B", "9A", "9B"],
          ByPartner: [],
          Moods: [2],
        },
        active: false,
      },
      {
        id: "4",
        name: "Bambou",
        color: "#53546c",
        quantity: 0,
        info: {
          id: 485,
          date_created: "2022-03-02T06:51:11.737Z",
          date_updated: "2022-03-02T10:04:20.669Z",
          Name: "Bambou",
          LatinName: "Phyllostachys bissetii",
          Preview: "65d10049-7431-45d2-9b75-7a39ef1efa0c",
          FlowerSize: null,
          BlossomPeriod: null,
          PlantingPeriod: ["9", "3", "4", "5", "10", "11"],
          FlowerForm: null,
          Hash: "promesse:bambou-phyllostachys-bissetii",
          SunExposure: ["full-sun", "partial-shade"],
          Height: 6,
          Spread: 8,
          Density: 1,
          Type: "tree-and-shrub",
          SoilType: ["clay", "clay-silty"],
          SoilPh: ["neutral", "acid", "alkaline"],
          SoilMoisture: ["tolerant"],
          ZoneUSDA: ["6A", "6B", "7A", "7B", "8A", "8B", "9A", "9B"],
          ByPartner: [],
          Moods: [3],
        },
        active: false,
      },
    ];

    onMounted(() => {
      stage = new Konva.Stage({
        container: "stageContainer",
        width: 842,
        height: 555,
      });
      layer = new Konva.Layer();
      stage.add(layer);

      stage.container().style.backgroundColor = "white";

      tr = new Konva.Transformer({
        centeredScaling: true,
        keepRatio: true,
      });

      stage.on("wheel", (e) => {
        zoomStage(e);
      });
      document.addEventListener("keyup", keyupHandler);
    });

    function uploadFile(error, file) {
      const reader = new FileReader();
      background = new Konva.Rect({
        x: 0,
        y: 0,
        width: stage.width(),
        height: stage.height(),
        // remove background from hit graph for better perf
        // because we don't need any events on the background
        draggable: false,
      });
      layer.add(background);

      var image = new Image();
      reader.addEventListener(
        "load",
        () => {
          // convert image file to base64 string
          image.onload = () => {
            background.fillPatternImage(image);

            layer.add(tr);

            tr.rotateEnabled(false);
            layer.draw();
          };
          image.src = reader.result;
          changeState();
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file.file);
      }
    }

    function handleUndo() {
      if (historyStep === 0) {
        return;
      }
      historyStep -= 1;
      const previous = history[historyStep];
      stage = Konva.Node.create(previous, "stageContainer");
    }

    function keyupHandler(event) {
      if (event.ctrlKey && event.key === "z") {
        handleUndo();
      } else if (event.ctrlKey && event.key === "y") {
        handleRedo();
      }
    }

    function handleRedo() {
      if (historyStep === history.length - 1) {
        return;
      }
      historyStep += 1;
      const next = history[historyStep];
      stage = Konva.Node.create(next, "stageContainer");
    }

    function changeState() {
      history = history.slice(0, historyStep + 1);
      const pos = stage.toJSON();
      history = history.concat([pos]);
      historyStep += 1;
    }

    function changeMenu(i) {
      if (i !== menus.value.length) {
        beforeMenu.value = currentMenu.value;
        currentMenu.value = i;
        drawer.value = true;
      }
    }
    function toggle() {
      drawer.value = !drawer.value;
    }

    function addPlant() {
      selectedPlants.value.push({
        id: (selectedPlants.value.length + 1).toString(),
        name: selectedPlant.value.LatinName,
        color: "#" + Math.floor(Math.random() * 16777215).toString(16),
        quantity: 0,
        info: selectedPlant.value,
      });
    }

    function createCancelButton(box) {
      var cancel = new Konva.Group({
        x: box.getWidth(),
        y: 0,
        draggable: false,
        id: "cancel",
      });

      var cancel1 = new Konva.Circle({
        width: 18,
        height: 18,
        fill: "#db3c30",
        id: "cancel",
      });

      var cancel2 = new Konva.Text({
        x: (cancel1.width() / 2) * -1,
        y: (cancel1.height() / 2) * -1,
        width: cancel1.width(),
        height: cancel1.height(),
        text: "x",
        fontSize: 10,
        fill: "white",
        align: "center",
        verticalAlign: "middle",
        id: "cancel",
      });
      cancelButton = cancel.add(cancel1).add(cancel2);
      cancelButton.width = box.getWidth();
      tr.add(cancel);
    }

    function addCercle(id) {
      if (actived.value) {
        id = parseInt(id) - 1;
        selectedPlants.value[id].quantity++;
        var group = new Konva.Group({
          x: stage.getPointerPosition().x,
          y: stage.getPointerPosition().y,
          width: selectedPlants.value[id].info.Height
            ? selectedPlants.value[id].info.Height * 10
            : 50,
          height: selectedPlants.value[id].info.Height
            ? selectedPlants.value[id].info.Height * 10
            : 50,
          draggable: true,
          id: `circle${selectedPlants.value[id].id}`,
        });

        var box1 = new Konva.Circle({
          width: selectedPlants.value[id].info.Height
            ? selectedPlants.value[id].info.Height * 10
            : 50,
          height: selectedPlants.value[id].info.Height
            ? selectedPlants.value[id].info.Height * 10
            : 50,
          fill: selectedPlants.value[id].color,
        });

        var box2 = new Konva.Text({
          x: (box1.width() / 2) * -1,
          y: (box1.height() / 2) * -1,
          width: box1.width(),
          height: box1.height(),
          text: selectedPlants.value[id].id,
          fontSize: selectedPlants.value[id].info.Height
            ? (selectedPlants.value[id].info.Height * 10) / 2
            : 20,
          fill: "white",
          align: "center",
          verticalAlign: "middle",
        });

        group.add(box1).add(box2);

        layer.add(group);
        changeState();

        group.on("dragmove", () => {
          group.x(Math.max(group.x(), box2.width() / 2));
          group.y(Math.max(group.y(), box2.width() / 2));
          group.y(Math.min(group.y(), stage.height() - box2.width() / 2));
          group.x(Math.min(group.x(), stage.width() - box2.width() / 2));
          changeState();
        });
        if (cancelButton !== null) {
          cancelButton.destroy();
        }

        createCancelButton(box1);
        tr.nodes([group]);

        group.on("mouseover", function () {
          document.body.style.cursor = "pointer";
        });
        group.on("mouseout", function () {
          document.body.style.cursor = "default";
        });

        group.on("transform", () => {
          tr.boundBoxFunc(function (oldBox, newBox) {
            // width and height of the boxes are corresponding to total absolute width and height of all nodes combined
            // so it includes scale of the node.

            if (newBox.width > 100) {
              return oldBox;
            }
            for (
              let i = 0;
              i < stage.find(`#circle${selectedPlants.value[id].id}`).length;
              i++
            ) {
              const width = newBox.width;
              stage.find(`#circle${id + 1}`)[i].children[0].width(width);
              stage.find(`#circle${id + 1}`)[i].children[1].fontSize(width / 2);
              selectedPlants.value[id].info.Height = width / 10;
            }

            return newBox;
          });
        });

        // clicks should select/deselect shapes
        stage.on("click tap", function (e) {
          // if click on empty area - remove all selections
          if (e.target === background) {
            tr.nodes([]);
            return;
          }

          // do we pressed shift or ctrl?
          const metaPressed = e.evt.ctrlKey;

          if (!metaPressed) {
            // if no key pressed and the node is not selected
            // select just one
            if (e.target.attrs.id == "cancel") {
              tr.nodes().forEach((el) => {
                console.log(el);
                el.destroy();
              });
              tr.nodes([]);
            } else {
              cancelButton.remove();
              cancelButton.destroy();
              tr.forceUpdate();
              createCancelButton(e.target.parent);
              tr.nodes([e.target.parent]);
            }
          } else if (metaPressed) {
            // add the node into selection
            const nodes = tr.nodes().concat([e.target.parent]);
            tr.nodes(nodes);
          }
        });
      }
    }

    function selectPlant(plant) {
      activePlant.value = plant;
      actived.value = true;
      var id = parseInt(plant.id) - 1;
      if (plant.active) {
        actived.value = false;
        selectedPlants.value[id].active = false;
      } else {
        actived.value = true;
        selectedPlants.value.forEach((element, index) => {
          if (index === id) {
            selectedPlants.value[index].active = true;
          } else {
            selectedPlants.value[index].active = false;
          }
        });
      }
    }

    function zoomStage(e) {
      // // stop default scrolling
      // e.evt.preventDefault();

      var scaleBy = 1;
      var oldScale = stage.scaleX();
      var pointer = stage.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? 1 : -1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) {
        direction = -direction;
      }

      var newScale = 0;

      if (direction > 0 && zoom.value < 100) {
        newScale = oldScale * scaleBy;
        zoom.value++;
      } else if (direction < 0 && zoom.value > 0) {
        zoom.value--;
        newScale = oldScale / scaleBy;
      } else {
        newScale = oldScale;
      }

      stage.scale({ x: newScale, y: newScale });

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
    }

    function save() {
      var dataURL = stage.toDataURL({ pixelRatio: 3 });
      var link = document.createElement("a");
      link.download = "stage.png";
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link = null;
    }

    function stageClick() {
      if (activePlant.value) {
        addCercle(activePlant.value.id);
      }
    }

    return {
      layer,
      stage,
      tr,
      menus,
      toast,
      zoom,
      save,
      drawer,
      actived,
      plants,
      toggle,
      zoomStage,
      stageClick,
      changeState,
      background,
      drawerBox,
      changeMenu,
      beforeMenu,
      currentMenu,
      uploadFile,
      addCercle,
      activePlant,
      addPlant,
      searchPlants,
      handleUndo,
      handleRedo,
      filteredPlants,
      selectedPlant,
      selectedPlants,
      createCancelButton,
      selectPlant,
    };
  },
};
</script>

<style lang="scss" scoped>
.global {
  width: 100%;
  height: 100%;
  padding: 5px;
  border-radius: 12px;
  background-color: white;
  height: calc(100vh - 9rem);
  box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%);
  .global-contain {
    overflow: hidden;
  }
  .contain {
    width: 100%;
    height: 100%;
    background-color: var(--surface-ground);
    // border: 1px solid #dee2e6;
    .contain-toolbar {
      width: 100%;
      background-color: white;
    }
  }
  #stageContainer {
    width: 842px;
    height: 555px;
    margin: auto;
  }
  .drawer {
    background-color: white;
    z-index: 10;
    .menu-item-drawer {
      span {
        font-size: 13px;
      }
      &:not(:nth-child(4)):hover {
        cursor: pointer;
        background-color: #dfefff8f;
      }
    }

    .box {
      height: 100%;
      background-color: white;
      border-right: 1px solid #dee2e6;
    }
  }
  .menu-details {
    height: 100%;
    width: inherit;
    background-color: white;
    border-right: 1px solid #dee2e6;
    position: relative;
    z-index: 2;
    .plant-box {
      &:hover {
        background-color: var(--indigo-50);
        cursor: pointer;
      }
    }
    ::v-deep(.filepond--root) {
      min-height: 160px;
      .filepond--panel-root {
        border: 2px dashed #d2d9ee;
        background-color: #f5f8ff !important;
      }

      .filepond--credits {
        display: none;
      }
      .filepond--drop-label {
        min-height: 160px;
      }
      .filepond--label-action {
        width: 100%;
        color: white;
        text-decoration: none;
        background-color: var(--indigo-500);
        height: 40px;
        font-size: 14px;
        border-radius: 5px;
      }
    }
    ::v-deep(.p-autocomplete) {
      margin-bottom: 80px;
      .p-autocomplete-input {
        width: 100%;
        background-image: url("../../../node_modules/primeicons/raw-svg/search.svg");
        background-size: 20px;
        background-position: 10px;
        background-repeat: no-repeat;
        text-indent: 20px;
        height: 50px;
      }
    }
  }
}

.toggle-button {
  position: relative;
  top: -50%;
  width: 50px;
  height: 80px;
  z-index: 1;
  transition: right 500ms;
  border-radius: 30px;
  transform: translate(0%, -50%);
  background-color: white;
  border: 1px solid #dee2e6;
}
.p-slider-vertical {
  height: 14rem;
}
.max__speed {
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
}
</style>
